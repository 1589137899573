<!-- @format -->
<script lang="ts" context="module">
  import { onMount } from "svelte";

  type DateFormat = "date" | "weekday" | undefined;
  type MobileLayout = "one/row" | "two/row";

  export type CampaignConfig = {
    date_format: DateFormat;
    filter_by_tag: number;
    filter_by_day: string | undefined;
    filter_by_store?: number[];
    filter_by_wishlist?: number[];
    filter_by_offer_content?: number[];
    mobile_offer_layout: MobileLayout;
    wish_list_enabled: boolean;
    venue_name: Readable<string>;
    locale: WritableAtom<string>;
    hide_default_text?: boolean;
    validity_date_font_size?: string;
    validity_date_font_color?: string;
    validity_date_background_color?: string;
    heart_background_color?: string;
    heart_color?:string;
    image_border?: string;
    image_border_color?: string;
    store_name_font_color?: string;
    store_name_background_color?: string;
    store_name_border?: string;
    store_name_border_color?: string;
    offer_card_background_color?: string;
    offer_card_font_color?: string;
  };
</script>

<script lang="ts">
  import { getStoresContext, type StoresKey } from "~/stores";
  import CampaignOfferItem from "./CampaignOfferItem.svelte";
  import { filterOffers } from "./utils";
  import { filter_by_category, filter_by_day, filter_by_store, filter_by_offer_content, user_likes, filter_by_wishlist } from "./store";
  import { getFragmentConfig, getFragmentConfigOr } from "../basic_component/util";
  import type { ManageAPI } from "~/manage-api/types";
  import { setContext } from "svelte";
  import { type Readable, derived } from "svelte/store";
  import { type WritableAtom } from "nanostores";
  import Modal from "./Modal.svelte";
  import OfferCardModal from "./OfferCardModal.svelte";

  export let request_store_key: StoresKey;
  export let fragment: Fragment;
  
  let wish_list_enabled = getFragmentConfigOr("wishList", fragment, false); // wishlist button enabled/ disabled from the fragment
  let wish_list_toggle = false // when user clicks on the wish list button to see their liked offers in the UI

  const stores_context = getStoresContext(request_store_key);
  const { T, offers_ext, locale, site } = stores_context;
  const config: CampaignConfig = {
    date_format: getFragmentConfig("validDateFormat", fragment),
    filter_by_tag: getFragmentConfig("filterByCategoryID", fragment) || 0,
    filter_by_day: getFragmentConfig("filterByDay", fragment),
    locale,
    mobile_offer_layout: getFragmentConfig("offerMobile", fragment) || "one/row",
    wish_list_enabled,
    venue_name: derived(site, (site, set) => set(site?.shoppingCenterName || '')),
    filter_by_wishlist: wish_list_toggle ? $user_likes : undefined,
    hide_default_text: getFragmentConfigOr("hideDefaultText", fragment, false),
    validity_date_font_size: getFragmentConfigOr("validityDateFontSize", fragment, '16px'),
    validity_date_font_color: getFragmentConfigOr("validityDateFontColor", fragment, 'var(--brandColor2)'),
    validity_date_background_color: getFragmentConfigOr("validityDateBckgdColor", fragment, 'var(--brandColor1)'),
    heart_background_color: getFragmentConfigOr("heartBckgdColor", fragment, 'var(--brandColor1)'),
    heart_color: getFragmentConfigOr("heartColor", fragment, 'var(--brandColor2)'),
    image_border: getFragmentConfig("imageBorder", fragment),
    image_border_color: getFragmentConfig("imageBorderColor", fragment),
    store_name_font_color: getFragmentConfigOr("storeNameFontColor", fragment, 'var(--brandColor1)'),
    store_name_background_color: getFragmentConfigOr("storeNameBckgdColor", fragment, 'var(--brandColor2)'),
    store_name_border: getFragmentConfig("storeNameBorder", fragment),
    store_name_border_color: getFragmentConfig("storeNameBorderColor", fragment),
    offer_card_background_color: getFragmentConfigOr("offerCardBckgdColor", fragment, 'var(--brandColor1)'),
    offer_card_font_color: getFragmentConfigOr("offerCardFontColor", fragment, 'var(--brandColor2)')
  };

  setContext("config", config);

  let items: ManageAPI.OfferExt[] = [];
  let filter_items: ManageAPI.OfferExt[] = [];

  let wishlist: string | null;
  $: wishlist_url = "";
  let url: string | null;

  $: t = $T;

  $: if ($offers_ext?.items) {
    items = filter_items = filterOffers($offers_ext.items, config, true);
  }

  $: if (items.length || wish_list_toggle) {
    filter_items = filterOffers($offers_ext.items, {
      filter_by_day: $filter_by_day,
      filter_by_tag: $filter_by_category,
      filter_by_store: $filter_by_store,
      filter_by_offer_content: $filter_by_offer_content,
      filter_by_wishlist: wish_list_toggle ? $user_likes : undefined,
    });
  }

  $: if (wishlist) {
    const decodedIds = wishlist.split("a").map((e) => parseInt(e));
    const ids = decodedIds.map((a, index, b) =>
      b.slice(0, index + 1).reduce((a, b) => a + b),
    );
    localStorage.setItem("campaign_user_likes", ids.toString());
    config.filter_by_wishlist = ids;
    filter_items = filterOffers($offers_ext.items, config);
    wish_list_toggle = true;
  }

  $: if ($user_likes) {
    const ids = $user_likes.sort((a, b) => a - b);
    const string = ids.map((a, index, b) => a - (b[index - 1] | 0)).join("a");
    const encodedString = encodeURIComponent(string);
    wishlist_url = `${url}%3Fw=${encodedString}`;
  }

  onMount(() => {
    const urlParams = new URLSearchParams(window.location.search);
    url = window.location.origin + window.location.pathname;
    wishlist = urlParams.get("w");
  });

  function toggleWishList() {
    wish_list_toggle = !wish_list_toggle;
  }

  let scrollingPosition = 0;
  let elm: HTMLElement;
  let focused_offer: ManageAPI.Offer;
  function focusOffer(offer: ManageAPI.Offer) {
    focused_offer = offer;
    showModal = true
  }
  let showModal = false;
</script>

<svelte:window bind:scrollY={scrollingPosition} />

{#if $locale}
  <div class="container" bind:this={elm}>
    {#if items.length && config.wish_list_enabled}
      <div class="row">
        <div class="col-12 text-center">
          <button class="wishlist__btn" on:click={toggleWishList}
            >{wish_list_toggle ? t`offers.allOffers` : t`offers.showWishList`}</button>
        </div>
      </div>
    {/if}
    <div class="row pt-4">
      {#if filter_items.length}
        {#each filter_items as offer (offer.id)}
          <CampaignOfferItem {offer} {request_store_key} {fragment} on:click={()=>focusOffer(offer)} />
        {/each}
      {:else if !config.hide_default_text}
        <div class="col-12 text-center">
          <p>{t`offers.noOffers`}</p>
        </div>
      {/if}
    </div>

    {#if config.wish_list_enabled && wish_list_toggle && filter_items.length}
      <div class="send-email-wrapper">
        <a
          type="button"
          class="send-email-btn mx-auto mb-5 mt-4 w-100 d-block"
          href="mailto:?body={wishlist_url}"
        >
          {$T`offers.send-email`}
        </a>
      </div>      
    {/if}
  </div>
  {#if scrollingPosition > 800}
    <button class="back-to-top" on:click={() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }}>
      <i class="fas fa-angle-double-up"></i>
    </button>
  {/if}
{/if}

<Modal bind:showModal {request_store_key} {fragment}>
  <OfferCardModal {request_store_key} offer={focused_offer} />
</Modal>

<style lang="scss">
  .back-to-top {
    position: fixed;
    bottom: 25px;
    right: 25px;
    font-size: 20px;
    border-radius: 35px;
    background-color: var(--brandColor2);
    border: 3px solid var(--brandColor1);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--brandColor1);
    padding: 12px 20px;
    z-index: 2;
    width: 60px;
    height: 60px;
    cursor: pointer;
    @media screen and (max-width: 1400px) {
      bottom: 15px;
      right: 15px;
    }
    @media screen and (max-width: 767px) {
      bottom: 5px;
      right: 5px;
      padding: 8px 16px;
      width: 40px;
      height: 40px;
    }
  }

  .wishlist__btn {
    padding: 10px;
    margin: 7px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    background-color: var(--brandColor1);
    border: 3px solid var(--brandColor2);
    color: var(--brandColor2);
  }

  .send-email-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 200px;
  }
  .send-email-btn {
    padding: 15px 30px;
    margin-top: 15px;
    width: 100%;
    border: none;
    color: var(--brandColor1);
    border: 1px solid var(--brandColor1);
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    &:hover {
      background-color: var(--brandColor1);
      color: var(--brandColor2);
    }
  }
</style>
